/*------------------------------------------------------------------
Theme Name:	Aviato E-Commerce Template
Version:	2.0
Last change:	14/09/2017 
Author :	Themefisher
Website :	www.themefisher.com
-------------------------------------------------------------------*/



@import 'variables.scss';

@import 'media-query.scss';

@import 'typography.scss';

@import 'common.scss';

@import 'templates/header.scss';

@import 'templates/navigation.scss';

@import 'templates/slider.scss';

@import 'templates/products.scss';

@import 'templates/single-product.scss';

@import 'templates/pricing.scss';

@import 'templates/instagram.scss';

@import 'templates/blog.scss';

@import 'templates/single-post.scss';

@import 'templates/backgrounds.scss';

@import 'templates/contact.scss';

@import 'templates/footer.scss';






