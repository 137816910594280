.slider-item {
  text-align:center;
  background-size: cover;
  padding:200px 0;
  h1 {
    color:$light;
    font-weight:bold;
    font-size:60px;
  }
  p {
    color:$light;
  }
  .btn-main {
    margin-top:25px;
  }
  &.white-bg {
    .slide-inner {
      h1 {
        color:$black;
      }
      p {
        color:$black;
      }
    }  
  }
}


