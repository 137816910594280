ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
  color: $primary-color;
}

blockquote {
  font-size: 18px;
  border-color: $primary-color;
  padding: 20px 40px;
  text-align: left;
  color:#777;
}
.navbar-toggle .icon-bar {
  background: $primary-color;
}

input[type="email"],input[type="password"],input[type="text"],input[type="tel"]{
  border-radius:0;
  box-shadow:none;
  height:45px;
  outline: none;
  font-weight:200;
  font-size:12px;
  &:focus {
    box-shadow: none;
    border:1px solid $primary-color;
  }
}


.form-control {
  box-shadow: none;
  border-radius: 0;
  &:focus {
    box-shadow:none;
    border:1px solid $primary-color;
  }
}



// Button Style

.btn-main {
  background: $primary-color;
  color: $light;
  display: inline-block;
  font-size: 11px;
  letter-spacing: 1px;
  padding: 14px 35px;
  text-transform: uppercase;
  font-weight: 200;
  border-radius:0;
  &.btn-icon {
    i {
      font-size:16px;
      vertical-align:middle;
      margin-right:5px;
    }
  }
  &:hover {
    background: darken( $primary-color, 20% );
    color: $light;
  }
}


.btn-solid-border {
  @extend .btn-main ;
  border:1px solid $primary-color;
  background:$light;
  color:$primary-color;
}


.btn-transparent {
  @extend .btn-main;
  background:transparent;
  padding:0;
  color:$primary-color;
  &:hover {
    background:transparent;
    color:$primary-color;
  }
}

.btn-large {
  padding:20px 45px;
  &.btn-icon {
    i {
      font-size:16px;
      vertical-align:middle;
      margin-right:5px;
    }
  }
}
.btn-small {
  @extend .btn-main ;
  padding:8px 25px;
  font-size:10px;
}

.btn-round {
  border-radius:4px;
}
.btn-round-full {
  border-radius:50px;
}


.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}










.mt-10 {
  margin-top: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}



.btn:focus {
  color: #ddd;
}

.w-100 {
  width: 100%;
}
.margin-0 {
  margin:0!important;
}


#preloader {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}






.bg-shadow {
  background-color: $light;
  box-shadow: 0 16px 24px rgba(0,0,0,.08);
  padding:20px;
}

.bg-gray {
  background:#f9f9f9;
}


.section {
  padding:80px 0;
}

.title {
  padding:20px 0 30px;
  h2 {
    font-size:18px;
    text-align:center;
    text-transform:uppercase;
    letter-spacing:2px;
  }
}


// Category
.category-box {
  background-size:cover;
  margin-bottom:30px;
  min-height:350px;
  position:relative;
  overflow:hidden;
  width: 100%;
  @include mobile-xs {
    min-height:250px;
  }
  @include mobile {
    min-height:250px;
  }
  &.category-box-2 {
    min-height:730px;
    @include tablet {
      min-height:400px;
    }
  }
  &:hover img {
    transform: scale(1.1);
  }
  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
    height:auto;
    backface-visibility: hidden;
  }
  a {
    display:block;
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
  
  }
  .content {
    position:absolute;
    z-index: 999;
    top:0;
    padding:25px;
    @include tablet {
      top:50%;
      left:50%;
      transform:translate(-50% , -50%);
      text-align:center;
    }
    h3 {
      margin:0;
      color:#333;
      font-size: 20px;
      font-weight: 500;
      @include tablet {
        font-size:20px;
      }
    }
    p {
      margin:6px 0 0;
    }
  }
}



// Single Page
.page-header {
  background:#f5f5f5;
  margin-top:20px;
  border-bottom:none;
  padding:30px 0;
  h1 {
    font-weight:200;
    margin:0 0 6px 0;
  }
  .breadcrumb {
    background:transparent;
    padding:5px;
    margin:0;
    li {
        font-weight:200;
        font-size:12px;
      a {
        color:$black;
      }
    }
  }
}




.overly {
  position:relative;
  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.51);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.overly-white {
  position:relative;
  &:before {
    content: '';
    background: rgba(255, 255, 255, 0.70);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.page-wrapper {
  padding:70px 0;
}

// Social Media Icons 
.social-media-icons {
  ul {
    li {
      display: inline-block;

      a {
        font-size:18px;
        color: #333;
        display: inline-block;
        padding:7px 12px;
        color: $light;
        
      }
      .twitter {
        background: #00aced ;
      }
      .facebook {
        background: #3b5998 ;
        padding:7px 18px;
      }
      .googleplus{
        background: #dd4b39;
      }
      .dribbble {
        background: #ea4c89 ;
      }
      .instagram {
        background:#bc2a8d;
      }
    }
  }
}


.call-to-action {
  .subscription-form {
    input {
      height:50px;
    }
    .btn-main {
      font-size:14px;
    }
  }
}



.dropdown-slide {
  position: static;
  .open>a, .open>a:focus, .open>a:hover {
    background: transparent;
  }
  &.full-width {
    .dropdown-menu {
      left:0!important;
      right:0!important;
    }
  }
  &:hover .dropdown-menu {
    display:none;
    opacity: 1;
    display: block;
    transform: translate(0px ,0px);
    opacity: 1;
    visibility: visible;
    color: #777;
    transform: translateY(0px);
  }
  .dropdown-menu {
    border-radius:0;
    opacity: 1;
    visibility: visible;
    position: absolute;
    padding: 15px;
    border: 1px solid #ebebeb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition:.3s all;
    position: absolute;
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: visibility 0.2s, opacity 0.2s, transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
    @include mobile {
      transform:none;
    }
  }
}




.commonSelect {
  margin-left:10px;
  padding-right:6px;
  position:relative;
  &:before {
    content:'\f3d0';
    font-family: $icon-font;
    position:absolute;
    right: -4px;
    top: 4px;
    font-size: 10px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor:pointer;
    border:none;
    padding:0;  
    height:auto;
    color:#555;
    &:focus {
      box-shadow:none;
      border:none;
    }
  }
}

.tabCommon {
  .nav-tabs {
    border-bottom:0;
    margin-bottom:10px;
    li {
      margin-right:5px;
    }
    li.active a {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      color: #ffffff;
    }
    a {
      border-radius:0;
      background: #f9f9f9;
      &:hover {
        border:1px solid transparent;
        background:$primary-color;
        color:$light;
      }
    }
  }
  .tab-content {
    padding:20px;
    border:1px solid $border-color;
  }
}


.commonAccordion {
  .panel {
    border-radius:0;
    box-shadow:none;
    .panel-heading {
      background:transparent;
      padding:0;
    }
    .panel-title {
      position:relative;
      a {
        display:block;
        font-size:14px;
        text-transform:uppercase;
        padding:10px 10px;
      }
      a:before {
        color:#555;
        content: "\f209";
        position: absolute;
        right: 25px;
        font-family:$icon-font;
      }
      a.collapsed:before {
        content: "\f217";
      }
    }
  }
}

.commonAccordion-2 {
  @extend .commonAccordion;
}


.list-circle {
  padding-left:20px;
  li {
    list-style-type:circle;
  }
}


.play-icon {
  border:1px solid $border-color;
  display:inline-block;
  width: 60px;
  height:60px;
  border-radius:50px;
  font-size:30px;
  i {
    line-height:60px;
  }
}


.alert-common {
  border-radius:0;
  border-width:2px;
  i {
    margin:0 5px;
    font-size:16px;
  }
}
.alert-solid {
  background:transparent;
  color:$primary-color;
}

.buttonPart {
  li {
    @include mobile {
      margin-bottom:8px;
    }
    @include tablet {
      margin-bottom:8px;
    }
  }
}


.btn--floating {
  position: fixed;
  bottom: 3.25em;
  right: 3.25em;
  padding:14px 40px;
  letter-spacing: 1px;
  z-index: 10;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  border-radius: 100px;
  font-size: inherit;
  line-height: 1.625em;
  text-transform: uppercase;
  background:#425cbb;
  color:#fff;
  &:hover {
    color:#fff;
  }
}


